<template>
  <div>
    <b-form @submit.stop.prevent="formOnsubmit">
      <!-- Name input -->
      <b-form-group id="input-group-name" label="Nama:" label-for="input-name">
        <b-form-input
          id="input-name"
          v-model="form.name"
          placeholder="Nama Supplier"
        ></b-form-input>
        <small class="text-danger">{{ error.name }}</small>
      </b-form-group>

      <!-- Tipe Input -->
      <b-form-group id="input-group-type" label="Tipe:" label-for="input-type">
        <b-form-select v-model="form.type" :options="options"></b-form-select>
        <small class="text-danger">{{ error.type }}</small>
      </b-form-group>

      <!-- Phone input -->
      <b-form-group
        id="input-group-phone"
        label="Telepon:"
        label-for="input-phone"
      >
        <b-form-input
          id="input-phone"
          v-model="form.phone"
          placeholder="Telepon Supplier"
        ></b-form-input>
        <small class="text-danger">{{ error.phone }}</small>
      </b-form-group>

      <!-- Mobile Phone input -->
      <b-form-group
        id="input-group-mobile-phone"
        label="Telepon Seluler:"
        label-for="input-mobile-phone"
      >
        <b-form-input
          id="input-mobile-phone"
          v-model="form.mobile_phone"
          placeholder="Telepon Genggam Supplier"
        ></b-form-input>
        <small class="text-danger">{{ error.mobile_phone }}</small>
      </b-form-group>

      <!-- Address input -->
      <b-form-group
        id="input-group-address"
        label="Alamat:"
        label-for="input-address"
      >
        <b-form-textarea
          id="input-address"
          v-model="form.address"
          placeholder="Alamat Supplier"
          rows="4"
          max-rows="8"
        ></b-form-textarea>
        <small class="text-danger">{{ error.address }}</small>
      </b-form-group>

      <!-- Notes input -->
      <b-form-group id="input-group-notes">
        <label for="input-notes"
          >Catatan: <em class="text-muted">opsional</em></label
        >
        <b-form-textarea
          id="input-notes"
          v-model="form.notes"
          placeholder="Catatan Supplier"
          rows="4"
          max-rows="8"
        ></b-form-textarea>
        <small class="text-danger">{{ error.notes }}</small>
      </b-form-group>

      <!-- Submit & Cancel button -->
      <b-button type="submit" variant="primary">Simpan</b-button>
      <b-button
        type="button"
        class="ml-2"
        variant="default"
        @click="$router.push('/medicine/vendors/list')"
      >
        Batal
      </b-button>
    </b-form>
  </div>
</template>

<script>
import module from "@/core/modules/CrudModule.js";
import validation from "@/core/modules/ValidationModule.js";

export default {
  props: {
    form: Object,
    route: String,
  },

  data() {
    return {
      // Error
      error: {
        name: "",
        address: "",
        phone: "",
        mobile_phone: "",
        type: null,
        notes: "",
      },
      options: [
        { value: null, text: "Pilih Tipe", disabled: true },
        { value: 0, text: "Obat" },
        { value: 1, text: "Non obat" },
      ],
    };
  },

  methods: {
    async formOnsubmit() {
      // Make Request
      let response = await module.submit(this.form, this.route);
      // Check Response
      if (response.state == "error") {
        // Validation Error
        this.error = validation.setValidationError(
          this.error,
          response.error.errors
        );
      } else {
        // Success
        Swal.fire(response.success.title, response.success.message, "success");
        this.$router.push("/medicine/vendors/list");
      }
    },
  },
};
</script>

<style>
</style>